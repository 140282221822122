import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header";
import LandingSection from "./components/LandingSection";
import ProjectsSection from "./components/ProjectsSection";
import ContactMeSection from "./components/ContactMeSection";
import Footer from "./components/Footer";
import { AlertProvider } from "./context/alertContext";
import Alert from "./components/Alert";
import UserCenter from "./page/UserCenter";

function App() {

  const projects = [
    {
      title: "User Center",
      description:
          "The enterprise's core User Center System is a full-stack project based on Spring Boot for the backend and React for the frontend. It encompasses fundamental functionalities such as user registration, login, and querying. 🔥️",
      getImageSrc: () => require("./images/usercenter.png"),
      techStack: "Frontend: React, Ant Design Pro, Umi development framework. Backend: Java, Spring + SpringMVC + SpringBoot Frameworks, MyBatis + MyBatis Plus Data Access Frameworks, MySQL Database, jUnit Unit Testing Library",
      url:"http://user.zilinxu.com/",
    },
    {
      title: "Health Facility Employee Status Tracking System",

      description:
          "The Health Facility Employee Status Tracking System website is primarily built using PHP and MySQL, and it extensively employs various SQL functionalities.",
      getImageSrc: () => require("./images/photo1.png"),
      techStack: "PHP, Bootstrap, MySQL, Html, CSS",
      url:"http://hospital.zilinxu.com/",

    },

    {
      title: "Ristorante con Fusion",
      description:
          "The website for the restaurant \"Ristorante con Fusion\" is primarily constructed as a frontend project using JavaScript and Bootstrap.",
      getImageSrc: () => require("./images/photo2.jpeg"),
      techStack: "JavaScript, Bootstrap, jquery, Html, CSS",
      url:"http://restaurant.zilinxu.com/",

    },
    {
      title: "Personal Portfolio",
      description:
          "My personal website is a frontend project built using React and the Chakra UI framework, which is based on React.",
      getImageSrc: () => require("./images/photo3.png"),
      techStack: "React,chakra-ui, JavaScript, CSS",
      url:"http://www.zilinxu.com/",

    },


  ];
  return (
    <ChakraProvider>
      <AlertProvider>
        <main>
          <Header />
          <LandingSection />
          <ProjectsSection projects={projects} />
          <ContactMeSection />
          <Footer />
          <Alert />
        </main>
      </AlertProvider>
    </ChakraProvider>
  );
}

export default App;
