import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";



const ProjectsSection = ({ projects }) => {
  return (
    <FullScreenSection

        backgroundColor="#14532d"
      isDarkBackground
        py={16}
      alignItems="flex-start"
        id="projects-section"
      spacing={8}
    >

      <Heading as="h1" >
        Featured Projects
      </Heading>

      <Box
        display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            techStack={project.techStack}
            url={project.url}
            imageSrc={project.getImageSrc()}
            projects={projects} // Pass the projects data to CardAccordion
          />
        ))}

      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
